/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Pues ya está, ya he construido mi antena, es una antena muy simple pero he conseguido buenos resultados bueno aquí os dejo la explicación de como la he hecho."), "\n", React.createElement(_components.p, null, "Como tenia que usar mi tarjeta USB Wi-Fi, la idea era meter directamente la tarjeta dentro de la antena. Después de probar varias opciones conseguí un bote de pringles una de las antenas que mejores resultados calidad/coste. Esta es una de las antenas mas baratas que se puede, hacer."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Materiales:")), "\n", React.createElement(_components.p, null, "- Lata de pringles."), "\n", React.createElement(_components.p, null, "-Carton fino o cartulina A4."), "\n", React.createElement(_components.p, null, "-Papel de aluminio."), "\n", React.createElement(_components.p, null, "-Celo o cinta aíslante."), "\n", React.createElement(_components.p, null, "-Cola."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Herramientas:")), "\n", React.createElement(_components.p, null, "-Tijeras."), "\n", React.createElement(_components.p, null, "-Cutter."), "\n", React.createElement(_components.p, null, "-Compás."), "\n", React.createElement(_components.p, null, "-Regla."), "\n", React.createElement(_components.p, null, "Lo primero que hice fue hacer el agujero en la lata para que entrara la tarjeta, le hice unas pestañas para que se sujetara mejor y en estas le puse celo porque si no el cartón se empezaba a deshacer el problema es que así sujeta menos porque el celo hace que resbale."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2008/02/pringles1.jpg",
    title: "Pringles 1"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 128px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 75%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAQX/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAXbz6c4oFA//xAAZEAEBAQADAAAAAAAAAAAAAAABAgADERL/2gAIAQEAAQUCKmccovqcBeIN1v/EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPwGFf//EABURAQEAAAAAAAAAAAAAAAAAABAh/9oACAECAQE/AYf/xAAYEAACAwAAAAAAAAAAAAAAAAAAEAERMf/aAAgBAQAGPwIhXj//xAAZEAEBAQEBAQAAAAAAAAAAAAABEQAhMUH/2gAIAQEAAT8hDnKamBG+ai9+7y9PeYBgBDf/2gAMAwEAAgADAAAAEHvf/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARQf/aAAgBAwEBPxBbsGF//8QAGBEAAgMAAAAAAAAAAAAAAAAAAAERIUH/2gAIAQIBAT8QlMHbP//EABoQAQEBAQEBAQAAAAAAAAAAAAERACExQVH/2gAIAQEAAT8Q7hRPcQEia6TECoI8zxToCYv3DDqH66GIHzf/2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Pringles 1\"\n        title=\"Pringles 1\"\n        src=\"/static/680b283bad8948a37611061801e77f1a/474e0/pringles1.thumbnail.jpg\"\n        srcset=\"/static/680b283bad8948a37611061801e77f1a/474e0/pringles1.thumbnail.jpg 128w\"\n        sizes=\"(max-width: 128px) 100vw, 128px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), "\n", React.createElement(_components.p, null, "En la tarjeta puse una goma como tope para que se metiera lo que yo queria."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2008/02/pringles3.jpg",
    title: "pringles3.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 128px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 75%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwABAv/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAAB6UtAGes//8QAGxABAAICAwAAAAAAAAAAAAAAAQISAxEABCH/2gAIAQEAAQUCjkoPYEXbXZHH5Q5//8QAFREBAQAAAAAAAAAAAAAAAAAAABL/2gAIAQMBAT8BlD//xAAWEQEBAQAAAAAAAAAAAAAAAAAAERL/2gAIAQIBAT8BrT//xAAXEAADAQAAAAAAAAAAAAAAAAAAECER/9oACAEBAAY/AtI6v//EABwQAQABBAMAAAAAAAAAAAAAAAEAESExQVFhof/aAAgBAQABPyHNqO4FOF49RF9lN7MM3M65/9oADAMBAAIAAwAAABBjz//EABYRAQEBAAAAAAAAAAAAAAAAAABhcf/aAAgBAwEBPxCW3//EABYRAQEBAAAAAAAAAAAAAAAAAAABcf/aAAgBAgEBPxCuX//EABoQAQADAQEBAAAAAAAAAAAAAAEAETEhQaH/2gAIAQEAAT8QBBYCjpBVFcDJyQCizgmMIDlfZrYg8+p//9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"pringles3.jpg\"\n        title=\"pringles3.jpg\"\n        src=\"/static/8455c2e093d8e8c35646e7f691322f3b/474e0/pringles3.thumbnail.jpg\"\n        srcset=\"/static/8455c2e093d8e8c35646e7f691322f3b/474e0/pringles3.thumbnail.jpg 128w\"\n        sizes=\"(max-width: 128px) 100vw, 128px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2008/02/pringles2.jpg",
    title: "pringles2.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 128px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 75%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwABAv/EABYBAQEBAAAAAAAAAAAAAAAAAAABA//aAAwDAQACEAMQAAABxBXKLcR//8QAGRAAAwEBAQAAAAAAAAAAAAAAAAECEQME/9oACAEBAAEFAopRyj0KjSTFp//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AUf/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwFX/8QAGBAAAwEBAAAAAAAAAAAAAAAAARARIQD/2gAIAQEABj8CpG9NDsX/xAAaEAEAAgMBAAAAAAAAAAAAAAABABEQIUFx/9oACAEBAAE/IQN0mwidBnk9w1OYvH//2gAMAwEAAgADAAAAEI8//8QAFREBAQAAAAAAAAAAAAAAAAAAEQD/2gAIAQMBAT8QGF//xAAWEQEBAQAAAAAAAAAAAAAAAAAAEVH/2gAIAQIBAT8Qqtf/xAAaEAEAAwADAAAAAAAAAAAAAAABABEhMUGB/9oACAEBAAE/EKJEuhWArwVyMNlxxekqbBgKPdbFn//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"pringles2.jpg\"\n        title=\"pringles2.jpg\"\n        src=\"/static/a5a0426734dff10ab5ea40ad6056d681/474e0/pringles2.thumbnail.jpg\"\n        srcset=\"/static/a5a0426734dff10ab5ea40ad6056d681/474e0/pringles2.thumbnail.jpg 128w\"\n        sizes=\"(max-width: 128px) 100vw, 128px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), "\n", React.createElement(_components.p, null, "Lo siguiente fué hacer un cono para mejorar la recepción de la antena, cojí un trozo de cartón y lo recorté de esta forma."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2008/02/pringles4.jpg",
    title: "pringles4.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 128px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 75%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEDAv/EABYBAQEBAAAAAAAAAAAAAAAAAAABA//aAAwDAQACEAMQAAABspPFM0L/AP/EABoQAQABBQAAAAAAAAAAAAAAAAIBABARIjH/2gAIAQEAAQUCgEjRUhGe3//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/AYf/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwGn/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAECIRIggf/aAAgBAQAGPwLKfEUmi9P/xAAZEAADAQEBAAAAAAAAAAAAAAAAARExQSH/2gAIAQEAAT8hQGtXDWcmPKYpUvmtuYcHvp//2gAMAwEAAgADAAAAEBwP/8QAFxEBAQEBAAAAAAAAAAAAAAAAEQAxUf/aAAgBAwEBPxBax2//xAAXEQADAQAAAAAAAAAAAAAAAAAAARFR/9oACAECAQE/EGmFP//EABsQAQADAQADAAAAAAAAAAAAAAEAESFRMXGh/9oACAEBAAE/EDNnZgLDJ6X7HoUM0jpcFWfExox2lK9Wf//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"pringles4.jpg\"\n        title=\"pringles4.jpg\"\n        src=\"/static/20342475c31f708499e5f69b97700cef/474e0/pringles4.thumbnail.jpg\"\n        srcset=\"/static/20342475c31f708499e5f69b97700cef/474e0/pringles4.thumbnail.jpg 128w\"\n        sizes=\"(max-width: 128px) 100vw, 128px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), "\n", React.createElement(_components.p, null, "Hay que tener en cuenta que al doblar este cartón entre en la lata, el perimetro de la lata es de aproximadamente 25cm, el radio de mi circulo interior es de 10cm y el del exterior de 16cm que es lo que me daba el folio de sí."), "\n", React.createElement(_components.p, null, "Envolví este cartón en papel de aluminio, ya que el cartón es trasparente a las microondas que es con lo que funciona el Wi-Fi y necesita un metal para que estas ondas se reflejen. Primero pegué el aluminio con cola al cartón y luego en los bordes puse celo para que no se rompiera."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2008/02/pringles5.jpg",
    title: "pringles5.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 128px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 75%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABYBAQEBAAAAAAAAAAAAAAAAAAEAA//aAAwDAQACEAMQAAABdImrMzy4n//EABoQAQACAwEAAAAAAAAAAAAAAAECAwAEESH/2gAIAQEAAQUCq14MJ1DjRLp0F9z/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPwEn/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/AbWv/8QAGhAAAgIDAAAAAAAAAAAAAAAAABEBEDFBUf/aAAgBAQAGPwJ6OGYE7//EABsQAAMAAwEBAAAAAAAAAAAAAAABESFhcTGB/9oACAEBAAE/Ibjno89vkVwZ9Jt4nVktWhvZ/9oADAMBAAIAAwAAABD0H//EABcRAAMBAAAAAAAAAAAAAAAAAAABESH/2gAIAQMBAT8QR3SM/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPxBEws//xAAdEAEAAgEFAQAAAAAAAAAAAAABABExIUFRYaGB/9oACAEBAAE/EALCrWHrH6dMdO+YgBebHkeLONjKIYREvRHyf//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"pringles5.jpg\"\n        title=\"pringles5.jpg\"\n        src=\"/static/8999573fb64925158b4c0301ac6b5c55/474e0/pringles5.thumbnail.jpg\"\n        srcset=\"/static/8999573fb64925158b4c0301ac6b5c55/474e0/pringles5.thumbnail.jpg 128w\"\n        sizes=\"(max-width: 128px) 100vw, 128px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), "\n", React.createElement(_components.p, null, "El siguiente paso fue pegar el cono a la lata para esto utilize celo."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2008/02/pringles6.jpg",
    title: "pringles6.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 128px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 75%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAgD/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAEJjEE6l//EABoQAAMAAwEAAAAAAAAAAAAAAAABAgMEERP/2gAIAQEAAQUCWSYIpVVa8HEzzOH/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwGI/8QAFhEBAQEAAAAAAAAAAAAAAAAAABES/9oACAECAQE/Aa0//8QAGxAAAQQDAAAAAAAAAAAAAAAAAQACECEREiL/2gAIAQEABj8C1LVg0Fwaio//xAAZEAEAAwEBAAAAAAAAAAAAAAABABEhMVH/2gAIAQEAAT8hA6DyYpqdZeN6dj0kQNxnAn//2gAMAwEAAgADAAAAEPjf/8QAFxEAAwEAAAAAAAAAAAAAAAAAARARQf/aAAgBAwEBPxC8K//EABcRAAMBAAAAAAAAAAAAAAAAAAABEUH/2gAIAQIBAT8QjUUf/8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFBUeH/2gAIAQEAAT8QwJTDBL/uR6gljt7Ktt35OFlliafOpTIDwwn/2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"pringles6.jpg\"\n        title=\"pringles6.jpg\"\n        src=\"/static/30ad8a18db61ad43b1698914786bcba7/474e0/pringles6.thumbnail.jpg\"\n        srcset=\"/static/30ad8a18db61ad43b1698914786bcba7/474e0/pringles6.thumbnail.jpg 128w\"\n        sizes=\"(max-width: 128px) 100vw, 128px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "  ", React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2008/02/pringles7.jpg",
    title: "pringles7.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 128px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 75%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAQX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABxV64i45wv//EABkQAAIDAQAAAAAAAAAAAAAAAAECAAMSEf/aAAgBAQABBQK1uStiTUyhHr0MchXU/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BR//EABURAQEAAAAAAAAAAAAAAAAAAAAS/9oACAECAQE/AVP/xAAYEAADAQEAAAAAAAAAAAAAAAAAAREhMf/aAAgBAQAGPwKkZrNL1lp//8QAGhAAAwADAQAAAAAAAAAAAAAAAAERITFRcf/aAAgBAQABPyFi9Mi8iMwmOHGW96Jwo0eH/9oADAMBAAIAAwAAABAfD//EABcRAQADAAAAAAAAAAAAAAAAAAARQXH/2gAIAQMBAT8QmmX/xAAWEQEBAQAAAAAAAAAAAAAAAAAAIRH/2gAIAQIBAT8QmIf/xAAaEAEBAQEBAQEAAAAAAAAAAAABEQAhUTFB/9oACAEBAAE/ELsV4DokilEJgRpf3zT7wkPdaYKHDjOCSknxv//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"pringles7.jpg\"\n        title=\"pringles7.jpg\"\n        src=\"/static/52f6189305e3c85f676be636795ebac9/474e0/pringles7.thumbnail.jpg\"\n        srcset=\"/static/52f6189305e3c85f676be636795ebac9/474e0/pringles7.thumbnail.jpg 128w\"\n        sizes=\"(max-width: 128px) 100vw, 128px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), "\n", React.createElement(_components.p, null, "La verdad es que me gusta como ha quedado aún se podria mejorar pero mi objetivo era conseguir una antena muy barata y con buena recepción asi que ahora a ver los resultados."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2008/02/pringles8.jpg",
    title: "pringles8.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 128px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 75%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEC/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQAD/9oADAMBAAIQAxAAAAGM3KrQf//EABsQAAEEAwAAAAAAAAAAAAAAAAEAAgMRBBIT/9oACAEBAAEFAnS0OrdQbEmTGTTSF//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/AYf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAABBQEAAAAAAAAAAAAAAAAAAQIQESFh/9oACAEBAAY/AsadjGrRcf/EABgQAQEBAQEAAAAAAAAAAAAAAAEAEWEx/9oACAEBAAE/IQIT3GE07xDtYXpMBGAy/9oADAMBAAIAAwAAABCzD//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEUH/2gAIAQMBAT8Q1xjQv//EABYRAQEBAAAAAAAAAAAAAAAAAAEQYf/aAAgBAgEBPxB0n//EABsQAAMBAQADAAAAAAAAAAAAAAERIQAxcYGx/9oACAEBAAE/EJopUfPmIVmQ0YEdy6jcBPWrmxCoR4wwKb//2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"pringles8.jpg\"\n        title=\"pringles8.jpg\"\n        src=\"/static/a3f493ab4e975aeb50aa19404f8a40bf/474e0/pringles8.thumbnail.jpg\"\n        srcset=\"/static/a3f493ab4e975aeb50aa19404f8a40bf/474e0/pringles8.thumbnail.jpg 128w\"\n        sizes=\"(max-width: 128px) 100vw, 128px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), "\n", React.createElement(_components.p, null, "Así que me puse a trabajar con kismet, sin antena detecto tres redes, la mia y dos mas sin casi señal. Conecto la antena y en kismet le doy al grafico de paquetes/minuto pulsando “r”. El resultado es este:"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2008/02/paquetes2.png",
    title: "paquetes2.png"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 128px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 68.75%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAABaklEQVQ4y42T63KCMBSEeR4FgVy5q4gWrQWU8f0fZTsnQVsLSn/s5MKZL5sNxzm1FyTbNdJyC5kn8BTDSvNnRYP0KzGILAYvBByPKyxDjmUo4IYCHlPwmBxk54GM4Ivoae+v3FDCT0I4gYrgCYmVkPD4WC4XCJSGLzU8LiZrrBSCNITjy+hNkT2dDg0IyGbq/gN0mTTurMN3wIdDPQMUCHUEpmMzn3VoMpwFxuBxOgNUY6A7ETpBqEYkqRlXQk2DKZrfGVIhOfHEGEiwYldB5wVEmpna2UehLPNdBZnmWAT84YLmBDy1nfmelTvwOBm7vDukn5Y+UkbHpkVR7Q2U3MTrDUSSYXP4wFff43A+o24ayDSbBD4c3oGfl4tV1+HYtkanrkPT9zhfr2bd3m6Iio3prpFD6hSbm81vvT88VNZHlHVt19XejHRlcks3mHLIcg7HBDw8hO1pZkY6wFcai4AN+z961YJhxvANZ6LFmcVDBtAAAAAASUVORK5CYII='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"paquetes2.png\"\n        title=\"paquetes2.png\"\n        src=\"/static/545546146dd1f3715e6859427da8301e/750d2/paquetes2.thumbnail.png\"\n        srcset=\"/static/545546146dd1f3715e6859427da8301e/750d2/paquetes2.thumbnail.png 128w\"\n        sizes=\"(max-width: 128px) 100vw, 128px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), "\n", React.createElement(_components.p, null, "Podeis ver como cuando conecto la antena y la dirijo hacia la calle el grafico llega a 27 paquetes. Esto se produce porque en ese momento detecta cuatro nuevas redes una de ellas porcierto sin cifrar, parece mentira que aún haya gente que no tenga ni la mas mínima seguridad en su wifi."), "\n", React.createElement(_components.p, null, "Bueno esto es todo, espero que os haya gustado, para mi ha sido un pequeño reto de estos que el conseguirlo te quedas satisfecho y como me ha gustado tanto supongo que continuaré haciendo este tipo de cosas."), "\n", React.createElement(_components.p, null, "Tengo otros proyectos en mente así que ya sabeis DIY forever."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
